@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons%7CMaterial+Icons+Outlined");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 400;
}
html {
  height: -webkit-fill-available;
}

body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

#root {
  color: #0d0d0d;
  background-color: #f2f2f2;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  height: 100vh;
  display: grid;
  width: 100%;
  grid-template-rows: auto 1fr auto auto;
  grid-template-areas:
    "header"
    "main"
    "nav"
    "detail";
}
